import React from "react";
import { useIntl,  } from "gatsby-plugin-intl";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { ContentPageDocs } from "../components/pageShared";

/**
 * PrivacyPolicyPage
 */
const PrivacyPolicyPage = () => {
  const intl = useIntl();
  const _pageId = "privacyPolicy";
  const _title = intl.formatMessage({
    id: `content.contentPages.${_pageId}.title`,
  });

  return (
    <Layout>
      <SEO title={_title} />
      <ContentPageDocs title={_title} type="pp" />
    </Layout>
  );
};

export default PrivacyPolicyPage;
